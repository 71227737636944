#helpNetF1 {
width: 100%;
height: 280px;

background-color: black;
background-image: url('/src/assets/images/zapravka.jpg');
background-size: cover;
display: flex;
flex-direction: column;
}
.helpNetH1 {color:white!important;text-align: center;    font-size: 6rem;
    padding-top: 5rem;
    }
.helpNetH2 {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 2.86rem;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $white;
    }
.helpNetBtn {width: 16rem!important;
    height: 5.6rem;
    gap: 8px;
    border-radius: 8px ;
    background: $prime;
    color:$white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.parallax-window {
    background: transparent;
  }