#remont{
    width: 100%;
background-color: $lightGrey;
margin-top: 5rem;
}
.rmnt{ display: grid;
    grid-template: auto / 1fr;
    justify-items: center;
    gap: 1rem;
    justify-content: center;
    @media ($tablet) {
        grid-template: auto / 1fr;
     }
     @media ($desktop) {
        grid-template: auto / 1fr  1fr;
      }}
.rmntImg {
    height: 31rem;
    @media ($tablet) {
        height: 43rem;
     }
     @media ($desktop) {
        height: 48.1rem;
      }
}
.rmntOpis{display: flex;flex-direction: column;justify-content: center; padding: 1rem;}
.rmtName{font-family: $mainFont;
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 3.8rem;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    font-weight: 500;
    @media ($tablet) {
        font-size: 4.8rem;
        line-height: 3.8rem;
     }
     @media ($desktop) {
        font-size: 4.8rem;
        line-height: 5.73rem;
      }
    }
.rmntPodpis {
    height: 58px;
    color:$grey07;
    font-family: $mainFont;
font-size: 2.4rem;
font-weight: 400;
line-height: 2.86rem;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
margin: 10px 0 10px;
    }
.rmntZajavka{padding: 2rem;
    width: 100%;
    height: auto;
border-radius: 16px;
background: linear-gradient(90.34deg, #F7355D 0.3%, #9747FF 101.33%);
 flex-direction: column;
 @media ($tablet) {
   
 }
 @media ($desktop) {
    width: 486px;
    height: 196px;
}
   
}
.rmntZajavkaName {
    font-family: $mainFont;
font-size: 4rem;
font-weight: 700;
line-height: 4.7rem;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: $white;
}
.rmntZajavkaNameOpis{
    font-family: $mainFont;
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.3rem;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $white;
    }
.rmntZajavkaOut {
    display: flex;
    margin-top: 2rem;
    flex-wrap: nowrap;
    flex-direction: column;
    @media ($tablet) {
        flex-direction: column;
   
     }
     @media ($desktop) {
        flex-direction: row;
    justify-content: space-around;
}
      }
    
.rmmntZajavkaOutInpt{
    width: 100%;
    height: 5.6rem;

padding: 13px 17px;
gap: 0px;
border-radius: 8px;
border: 1px 0px 0px 0px;
font-family: $mainFont;
font-size: 2.4rem;
font-weight: 400;
line-height: 2.86rem;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
margin-bottom: 0.5rem;
@media ($tablet) {
    width: 100%;

 }
 @media ($desktop) {
    width: 24.6rem;

  }
}
.rmntZajavkaOutBtn {
    width: 100%;
    height:5.6rem;
    border-radius: 8px;
    font-family: $mainFont;
font-size: 1.6rem;
font-weight: 500;
line-height: 1.91rem;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: $grey07;
cursor: pointer;
@media ($tablet) {
    width: 100%;

 }
 @media ($desktop) {
    width: 16rem;

  }
}
.rmntZajavkaOutBtn:hover{
    background-color: $grey03;
    color: $black;
}