.cartItem{   
width: 100%;
gap: 0px;
border-radius: 16px;
display: flex;
flex-direction: column;
padding-top: 2rem;

&_name{
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.909rem;
    text-align: left;
    color:$black;
    }
&_Stroks{width: 100%;display: flex;
    flex-direction: column;
    @media ($desktop) {
        width: 70%;padding: 1rem;
        //grid-template: auto / 1fr  1fr 1fr 1fr;
      }
}
&_Stroka{box-shadow: 0px 4px 8px 0px #0000000D;width: 100%;border-radius: 8px;
    display: grid;
    grid-template: auto /1fr;
    align-items: center;
    
    margin-bottom: 1rem;
    padding: 1rem 0;
    cursor: pointer;
    @media ($desktop) {
        grid-template: auto /1fr 15% 10%;
        width: auto;
      }
    
}   
&_Stroka:hover{box-shadow: 0px 4px 8px 0px #0000004f;border-radius: 8px;} 
&_Summa{box-shadow: 0px 4px 8px 0px #0000000D;width: 100%;height: auto;
    @media ($desktop) {
        width: 30%;
        box-shadow: 0px 4px 8px 0px #0000000D;
        //grid-template: auto / 1fr  1fr 1fr 1fr;
      }
}
@media ($desktop) {
    flex-direction: row;
  }
&_del{font-size: 2rem;color:$prime;margin-right: 1rem;cursor: pointer;    text-align: right;}
&_Price{display: flex;   justify-content: space-evenly;    align-items: center;}
&_PriceOld{
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.909rem;
    text-align: left;
    text-decoration-line: line-through;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $grey07;
    }
&_PriceNow{
font-size: 1.6rem;
font-weight: 700;
line-height: 1.909rem;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
counter-reset: $black;
}

&_Kolvo{
    width: 7.1rem;
    height: 3.2rem;
    border-radius: 8px;
    background: #FAFAFA;
    text-align: center;
    color: $prime;
    font-weight: 600;
    font-size: 1.6rem;
    }
&_back{
    font-size: 1.6rem;
font-weight: 700;
line-height: 1.909rem;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: $grey05;
margin-top: 3rem;
}
&_back:hover {color: $prime;cursor: pointer;}

}
//.cartItem:hover{box-shadow: 0px 4px 8px 0px #0000004f;}
.cartSumma{
   padding: 2rem;
&_Dostavka {   font-size: 2rem;display: flex;        justify-content: space-between;
font-weight: 500;
line-height: 2.387rem;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: $grey09;
}
&_phone{
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 2.864rem;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $black;
    margin-top: 1rem;
    }
&_Dostavka_Summ {
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.387rem;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $grey07;
    }
&_dstvk {
    height: 24px;
    top: 235px;
    left: 396px;
    gap: 0px;
    color: $grey09;
    font-size: 2rem;
    font-weight: 700;
    }

&_radio{display: flex;justify-content: space-around;}
&_radio  input[type=radio] + label{ height:40px;font-size: 1.6rem;}
&_radioInpt {width: 2.4rem;
    height: 2.4rem;
    top: 4px;
    left: 4px;
    gap: 0px;
    border-radius: 500px 0px 0px 0px;
    opacity: 0px;
    }
&_select{
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.909rem;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $black;
    width: 100%;
    height: 40px;
    top: 267px;
    left: 396px;
    padding: 8px 0px 0px 0px;
    gap: 4px;
    border-radius: 8px;
    background: $lightGrey;
    border: none;
}
&_btn{    color: #fff;
    cursor: pointer;
    background: #0071e4;
    border-radius: 16px;
    align-items: center;
    width: 100%;
    height: 56px;
    font-size: 2rem;}
&_btn:hover{background: #004d9b;}
}
.cartItem_del{ grid-column: 1;
    grid-row: 1;
    @media ($desktop) {
        grid-column: 3;
        grid-row: 3;
        margin-top: -6rem;
    }
}
.popup-cart {display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;}
.popup-zvonok {
    background: #fff;
    border-radius: 5px;
    height: 33rem;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    box-shadow: 0 2px 10px rgba(0,0,0,.1);
}
.cartSumma_radio {
    border:none;
}
.cartSumma_radio input {cursor: pointer;}