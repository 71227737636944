.ktlg{
    font-size: 1.6rem;
    color: $prime;
    font-weight: 600;
  //  display: grid;
  //  grid-template: auto / 1fr ;
&_description{background:$lightGrey ;
  display: none;
  @media ($tablet) {display: none;
  }
  @media ($desktop) {display: block;
  }
}

&_subMenu{ color: $grey07;    column-count: 1;line-height: 3rem;padding: 1rem; padding-left: 47px;
 grid-template: auto / 1fr ;
 @media ($tablet) {
    column-count: 2;
  }
  @media ($desktop) {
    column-count: 3;
  }
}
&_separator {
  @media ($desktop) {
    box-shadow: -4px 0 #0000000d;
  }
}

&_subMenu li:hover{color: $prime!important;}
&_menu {
    display: none;
    @media ($desktop) {
        display: block;
      }
    
}
&_H3 {
    display: none;
    padding-left: 1rem;
    @media ($desktop) {
        display: block;
      }
    
}

&_select{
    margin-top: 1rem;width: 100%;padding: 1rem;
    @media ($desktop) {
        display: none;
      }


}
@media ($desktop) {
    grid-template: auto / 1fr 20% ;
  }
}
.ktlg ul li:hover{color: $grey07;background: #f5f5f5!important;}
.ktlg ul {margin-top: 3rem;padding-right: 1rem;  margin-bottom: 3rem;}
.ktlg ul li { list-style: none;
    cursor: pointer;
    line-height: 3rem;
    margin-left: -3.7rem;
    padding: 0 1rem;
}
.description_cat{

    text-align: left;
    font-size: 1.3rem;
    height: 7.5rem;
    overflow-y: auto;
    line-height: 1.7rem;
    margin-top: 1rem;
    padding: 1rem;
    color: $grey06;
}
.group_description_block {
  font-size: 1.6rem;
  margin-top: 1.3rem;
  background: #f5f5f5;
  padding: 1rem;
  border-radius: 8px;
}
#ktlg{overflow-y: auto;}
