.bestH1{
    font-family: $mainFont;
font-size: 3.2rem;
font-weight: 700;
line-height: 3.82rem;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
margin-top: 3rem;
font-weight: 500;

}
.itemBests{margin-top: 4rem;
    /*display: flex;justify-content: space-around;
    */
    display: grid;
    grid-template: auto / 1fr;
    justify-items: center;
    gap: 1rem;
    @media ($tablet) {
        grid-template: auto / 1fr 1fr;
     }
     @media ($desktop) {
        grid-template: auto / 1fr  1fr 1fr 1fr 1fr;
      }
         
}
.itemBest{
width: 228px;
height: 500px;
border-radius: 16px;
display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.itemBestName{font-family: $mainFont;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 2.87rem;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    }
.itemBestOpis{
    font-family: $mainFont;
font-size: 1.6rem;
font-weight: 400;
line-height: 1.91rem;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: $grey07;
text-align: justify;
padding: 0 1rem;
}
.itemBestBtn {
    width: 21.2rem;
height: 5.6rem;
border:2px solid $prime;
border-radius: 8px;
display: flex;
align-items: center;
justify-content: center;
font-family: $mainFont;
font-size: 1.8rem;
font-weight: 500;
line-height: 2.15rem;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: $prime;
cursor: pointer;
}
.itemBestBtn:hover {
background: $prime;
color: $white;
}