@mixin h1($color:$black) {
    font-size: 2.4rem;
    color: $color;
    font-famaly: $mainFont;
    font-weight: 500;
    @media ($tablet) {
        font-size: 3rem;
    }
    @media ($desktop) {
        font-size: 4.8rem;
    }
}
@mixin h2($color:$prime) {

    font-family: $mainFont;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.91rem;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color:$color;
    margin-bottom: 2rem;
    @media ($tablet) {
        font-size: 2.4rem;
        line-height: 2.86rem;
      
    }
    @media ($desktop) {
        font-size: 2.4rem;
        line-height: 2.86rem;
            }
}
@mixin itemProductKupitP ($color:$prime){
    font-family: $mainFont;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.67rem;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color:$color;
}