// @font-face {
//     font-family: 'SF Pro Display';
//     src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
//         url('../fonts/SFProDisplay-Medium.woff2') format('woff2'),
//         url('../fonts/SFProDisplay-Medium.woff') format('woff'),
//         url('../fonts/SFProDisplay-Medium.ttf') format('truetype');
//     font-weight: 400;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'SF Pro Display';
//     src: local('SF Pro Display Thin'), local('SFProDisplay-Thin'),
//         url('../fonts/SFProDisplay-Thin.woff2') format('woff2'),
//         url('../fonts/SFProDisplay-Thin.woff') format('woff'),
//         url('../fonts/SFProDisplay-Thin.ttf') format('truetype');
//     font-weight: 800;
//     font-style: normal;
//  }
@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
        url('../fonts/SFProDisplay-Regular.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Regular.woff') format('woff'),
        url('../fonts/SFProDisplay-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

