@import './utils';
@import './components';
@import './layout';
.container {
    overflow: hidden; 
    margin: auto;
    @media ($tablet) {
        width: 90%;
    }
    @media ($desktop) {
        width: 1440px
    }
}
.h1 {@include h1()}
.h2 {@include h2()}
.cntr {
    flex-direction: column;
    align-items: center;
    display: flex;
}
.top-info {width:100%;background: $lightGrey;display: none;
  
    @media ($desktop) {
        display: flex;
        height: 5.1rem;
    }
}
.f1-animation {
    transform-origin: center;
    animation: 1s linear infinite moving;
}
@keyframes moving {
    100% {
      transform: rotate(360deg);
    }
  }
.prime{color:$prime}
.topPanel{height: 4rem;color: $white;display: none;align-items: center;justify-content: space-between;
    @media ($tablet) {
        display: flex;
    }
    @media ($desktop) {
        display: flex;
        height: 5.1rem;
    }
}
#topPanel{    display: flex;
    align-items: center;
    justify-content: space-between;}
#topMenu{margin-left: 2rem;line-height: 2.15rem;height: 4rem;  display: none;  align-items: center;
    @media ($desktop) {
        display: flex;
        height: 5.1rem;

    }
}
#topMenu a{color: $black;font-size: 1.8rem;margin-left: 3.5rem;}
#topMenu a:hover{color: $prime;}
.phone{color:$black;font-size: 2rem;}
#phone{display: none;
    @media ($desktop) {
        display: flex;
        align-items: flex-start;
        padding-top: 1.8rem;
        padding-bottom: 0.5rem;
    }
}
.zvonit{font-size: 1.6rem;color: $prime;float: right;cursor: pointer;}
.garant{color:$prime!important;text-decoration: underline;}
#topPanelFix {height: 6rem;padding: 1rem;width: 320px;display: flex;    margin-top: 1rem; justify-content: space-between;align-items: center;
    box-shadow: 0px 4px 4px 0px #0000000d;
    z-index: 10;
    @media ($tablet) {
        width: 700px;
    }
    @media ($desktop) {
        display: none;
        height: 5.1rem;
    }
}
.topPanelFixNav{width: 50%;display: flex;justify-content: space-between;
    @media ($tablet) {
        width: 30%;
    }
}
.inptTablet {display: none;
    @media ($tablet) {
        display: flex;
    }
}
.noTablet{
   
    @media ($tablet) {
        display: none;
    } 
}
.noMbl{
        display: none!important;
        @media ($tablet) {
            display: flex!important;
        } 
}
#slider {
    width: 100%; 
}
.slider{

    width: 100%;
    background-color: #b3e0ff;
    @media ($tablet) {
        height: 310px;
    } 
    @media ($desktop) {
        height: 485px;
    } 
}
.akcii{background: #FDEFBF;
    padding: 0.5rem;
    border-radius: 10px;
}
.btnCat{font-size: 2rem;color:$white;background:$prime ;width: 215px;height: 56px;border-radius: 16px;display: flex;
    align-items: center; cursor: pointer;    margin-bottom: 1rem;
}
.btnCat:hover{background: #004d9b;}
.btnCatGreen{color: #fff;
    cursor: pointer;
    background: #52d116;
    border-radius: 16px;
    justify-content: space-around;
    align-items: center;
    width: 215px;
    height: 56px;
    font-size: 2rem;
    display: flex;
}
.btnCatGreen:hover{background:#2f9300}
#panelFixDesk{justify-content: space-between;
    height: 100px;
    align-items: center;
    display: none;
    @media ($desktop) {
        display: flex;
    }
}
#panelFixDesk input {border: none;width: 100%;font-size: 2rem;}
.search {
    display: inline-flex;
    border: 1px solid $grey03;
    border-radius: 16px;
    padding: 8px;
    width: 80rem;
    height: 56px;
    align-items: center;
}
  
  .search-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    order: -1;
  }
  
  .search-field {
    width: 300px;
    border: none!important;
  }
  
  .search-field:focus {
    outline: none;
    border: none!important;
  }
  
  .search-field:focus + .search-icon {
    /* стили для иконки при фокусе поля */ 
    border: none!important;
  }
  .btnKol-vo{
    background-image: url('../images/kol-vo.png');
    width: 34px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #panelFixDeskCat{justify-content: space-between;
    height: 80px;
    align-items: center;
    display: none;
   /* box-shadow: 0px 4px 4px 0px #0000000d;
   */ z-index: 10;
    @media ($desktop) {
        display: flex;
    }
}
#panelFixDeskCat a {font-size: 2rem;color: $black; margin-left: 5rem;}
#panelFixDeskCat div {display: flex;
align-items: center;cursor: pointer;    justify-content: space-around;padding-right: 1rem;}
#panelFixDeskCat div a:hover {color:$prime}
.constructor{width: 280px;
    height: 64px;
    top: 8px;
    left: 1040px;
    gap: 0px;
    border-radius: 8px 8px 8px 8px;
    color: $white; 
    font-family: SF Pro Display;
font-size: 2rem;
font-weight: 400;
line-height: 2.9rem;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
justify-content: center;
    background: linear-gradient(90deg, #000000 22.19%, #5E556B 100%);
    }
    .constructor:hover{background: linear-gradient(90deg, #000000 22.19%, #7d738b 100%);}
    .imgSlide{
height: 200px;
@media ($tablet) {
   height: 280px;
}
@media ($desktop) {
    height: 485px;
 }
    }

    .circle{
        background: linear-gradient(to right, red, $prime,#28186D);
        width: 48px;
        height: 48px;
        border-radius: 50px!important;
        display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
        
        }
        .imgLogo {    border: 3px solid #fff;
            box-sizing: border-box;
            border-radius: 50%;
            margin-top: 5px;
            margin-left: -82px;
        }
.sld{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    @media ($tablet) {
        flex-direction: row;
     }
     @media ($desktop) {
        flex-direction: row;
      }
}
.delo {
    color: #173d7b;
    height: 100%;
    font-size: 2.1rem;
    line-height: 2.5rem;
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    margin-bottom: 2rem;
    
@media ($tablet) {
    line-height: 2.8rem;
    font-size: 2.1rem;
    margin-bottom: 0;
 }
 @media ($desktop) {
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 0;
  }
}
.cat1{
    background-image: url('/src/assets/images/iphonex.svg');
    background-repeat: no-repeat;
    height: 50px;
    text-align: right;
}
.cat1:hover{
    background-image: url('/src/assets/images/iphonex-blue.svg');
}
.cat2{
    background-image: url('/src/assets/images/laptop.svg');
    background-repeat: no-repeat;
    height: 48px;
    text-align: right;
}
.cat2:hover{
    background-image: url('/src/assets/images/laptop-blue.svg');
}
.cat3{
    background-image: url('/src/assets/images/noutbook.svg');
    background-repeat: no-repeat;
    background-size: 48px;
    height: 48px;
    text-align: right;
}
.cat3:hover{
    background-image: url('/src/assets/images/noutbook-blue.svg');
}
.cat4{
    background-image: url('/src/assets/images/monitor.svg');
    background-repeat: no-repeat;
    height: 48px;
    text-align: right;
}
.cat4:hover{
    background-image: url('/src/assets/images/monitor-blue.svg');
}
.cat5{
    background-image: url('/src/assets/images/printer.png');
    background-repeat: no-repeat;
    height: 48px;
    text-align: right;
}
.cat5:hover{
    background-image: url('/src/assets/images/printer-blue.png');
}

.cat6{
    background-image: url('/src/assets/images/smart-home.png');
    background-repeat: no-repeat;
    height: 48px;
    text-align: right;
}
.cat6:hover{
    background-image: url('/src/assets/images/smart-home-blue.png');
}
.sldLogo{    
    margin-top: 10px;
    height: 48px;
    margin-left: 10px;
    @media ($tablet) {
        margin-top: 0;
        height: 37px;
        margin-left: 4rem;}    
     @media ($desktop) {
        margin-top: 0;
        margin-left: 4rem;height: 98px;
      }
}
.ftrLogoMbl{display: flex;

    @media ($desktop) {
        flex-direction: column;
      }
}
.popup-fade{
    width: 553px;
    height: 297px;
    border-radius: 16px;
    background: $lightGrey;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
    .popup-fadeProduct{
        width: 95%;
        height: auto;
        border-radius: 16px;
        background: $white;
    
        position: absolute;
        top: 70rem;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        
        @media ($tablet) {top: 45rem;}
        @media ($desktop) {
            width: 50%;
            height: 70rem;
            top:50%;
          }
        }
        .popup-fadeCatalog {
            background: #fff;
            border-radius: 5px;
            width: 95%;
            height: 95%;
            padding: 20px;
            position: absolute;
            top: 55%;
            left: 50%;
            -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
            @media ($tablet) {width: 95%;height: 95%;}
            @media ($desktop) {width: 80%;height: 80%;}
        }
.popup-close{color: $prime;font-size: 2.4rem;    float: right; cursor: pointer;
    padding: 1rem;}
.popupH1{
font-size: 3.2rem;
font-weight: 700;
line-height: 3.819rem;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: $black;
}
.popupPerezvonit{
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.909rem;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $grey07;
    }
.poupTelo{ flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: auto;
    display: flex;
    @media ($desktop) {
        height: 20rem;
      }
}
.popupBtn{width: 24.6rem;
    height: 5.6rem;
    gap: 8px;
    border-radius: 8px;
    background: $prime;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.909rem;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $white;
    text-align: center;
    cursor: pointer;
    }
.popupBtn{width: 24.6rem;}
.popupBtn:hover{background: #004d9b;}
.popup-overlay {
    position: fixed;top: 0;
    left: 0;margin-top:0;
    width: 100%;
    height: 100%;
    background-color: #3a3f4478;
    display: none;
    z-index: 11;
    backdrop-filter: blur(3px);
    overflow-y: auto;
    //margin-top: 15rem;
   //c
 }
.faCat{
    font-size: 3rem;margin-left: 2rem;color:$grey07;cursor: pointer;
}
.faCat:hover{color:$prime ;}
.top{display: none;
    @media ($desktop) {
    cursor: pointer;
    display: block;   
    position: fixed;
    bottom: 10rem;
    right: 5%;}
}
.popupCartItem{box-shadow: 0px 4px 8px 0px #0000000D;
display: flex;
flex-direction: column;
align-items: center;
width: 78px;
padding: 0.5rem;
gap: 10px;
cursor: pointer;
}
.popupCartItem:hover {box-shadow: 0px 4px 8px 0px #0000004b}
.page404{    display: flex;
    justify-content: center;    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 3rem;
    @media ($desktop) {margin-top: 0;
    }
}
.page404Podpis{color:$grey07;font-size: 1.6rem;}
.popupImg{
    width: 100%;
    @media ($tablet) {width: auto;
    }
}
.navRaz{color:$green;font-size: 1.6rem;font-weight: 600;}
nav  a {font-size: 1.6rem;}
#content{
    margin-top: 6rem;
    padding: 1.5rem;
    @media ($desktop) {margin-top: 0;
    }
}
.popup-fadeCatalog{background: #FFF;}
.cookies {
    padding: 10px;
    display: flex;
    background: #000000bd;
    color: #FFF;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);
    position: fixed;
    bottom: 4px;
    right: 3%;
    z-index: 1000;
    font-size: 1.6rem;
}

element.style {
}
.btn_cooki {
    background:#D52623;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 1rem;
}
.popup-fadeMsg{background: #669cdc6b;
    padding: 20px;
    border-radius: 8px;
    font-size: 1.6rem;
    position: fixed;
    top: 25%;
   z-index: 22;
}
.poupTeloZvonok{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 23.4rem;
}
.lupa {
    border-radius: 50%;
    width: 26px;
    height: 26px;
    margin-left: 2rem;
    }
.inptTop {
width: 226px;border: none;font-size: 1.6rem;
@media ($tablet) {
   width: 320px;
  }
}
.popup-gif {
    position: fixed;top: 0;
    left: 0;margin-top:0;
    width: 100%;
    height: 100%;
 }
 .demoTabs{color:#4a4440;background-color:#fff;display:block}.demoTabs__content{border-top:1px solid #d9d9d9;margin-top:.8rem;padding:16px}.demoTabs__tab,.demoTabs__area{display:none}.demoTabs__area p{margin-bottom:16px}.demoTabs__label{cursor:pointer;border:1px solid transparent;border-radius:8px 8px 0 0;flex-direction:column;margin-right:2px;padding:1rem;line-height:1.42857;display:flex}@media (min-width:767px){.demoTabs__label{display:initial}}.demoTabs__label:hover{background-color:#d9d9d9}.demoTabs__tab:checked+.demoTabs__label{background-color:#d9d9d9}.demoTabs #demoTab-1:checked~.demoTabs__content .demoTabs__area[data-tab=demoTab-1]{display:block}.demoTabs #demoTab-2:checked~.demoTabs__content .demoTabs__area[data-tab=demoTab-2]{display:block}.demoTabs #demoTab-3:checked~.demoTabs__content .demoTabs__area[data-tab=demoTab-3]{display:block}.font_xs{display:flex}
 .properties__title{width:100%}@media (min-width:767px){.properties__title{width:33%}}
 .properties__title:nth-child(even) {background: #f5f5f5;}
 .properties__value{margin-left:1rem}
 .popup-overlayChat{display: none;}