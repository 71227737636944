.product{
  margin-top: 3rem;
&_item{display:flex ;margin-top: 3rem;flex-direction: column;
  @media ($desktop) {
    flex-direction: row;
  }
  
}

&_h1 {margin-top: 2rem;font-size: 2.4rem!important;

    @media ($desktop) {
        font-size: 3.2rem!important;
      }
}
&_img{width:100%;    box-sizing: border-box;
  border: 1px solid #777;

  @media ($desktop) {
    width: 442px;
 // height: 333px;
  }
  
}
&_har{color:$prime;font-weight: 700;font-size: 2.4rem;
  font-weight: 700;
}
&_manuf{
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 3.2rem;
  color: $grey07;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;

  @media ($desktop) {
  margin-top: 3rem;
  }
}
&_property{  
  margin-top: 3rem;
  width: 100%;
  @media ($desktop) {
  margin-top: 0;
  padding-left: 3rem;
  }}
&_skolko{
  display: flex; display: flex;    justify-content: space-between;margin-top: 3rem;flex-direction: column;
  @media ($desktop) {
    flex-direction: row;
    }
}
&_kolvo{    display: flex;align-items: center;}
&_infa{
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 3rem;
  border-bottom: 1px solid #F3F3F3;
  padding-bottom: 2rem;
  font-weight: 500;
  font-size: 1.6rem;
  flex-direction: column;
  line-height: 30px;
  @media ($desktop) {
    flex-direction: row;
    }
}
&_summa{font-size: 1.6rem;line-height: 3rem;padding-top: 0.5rem;    display: flex;
  justify-content: space-between;flex-direction: row;
    
  @media ($desktop) {flex-direction: column;
    }
}
&_description{
  font-size: 2rem;
  font-weight: 400;
  margin-top: 3rem;
}
}
#minus {
  width: 41px;
  height: 36px;
  background: #D9D9D9;
  border-radius: 5px 0px 0px 5px;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
}
#plus {
  width: 41px;
  height: 36px;
  text-align: center;
  font-size: 24px;
  background: #D9D9D9;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}
#inpt_q {
  width: 49px;
  height: 36px;
  text-align: center;
  font-size: 17px;
  border: 1px solid #D9D9D9;
}
.nav-tabs {
  border-bottom: 1px solid #ddd;
  font-size: 1.6rem;
  width: 127px;

}
.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}
.nav-tabs>li>a:hover {
  border-color: #eee #eee #ddd;
}
.nav>li>a:focus, .nav>li>a:hover {
  text-decoration: none;
  background-color: #eee;
}
.nav-tabs>li>a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}
.nav>li>a {
  position: relative;
  display: block;
  padding: 10px 15px;
}
.nav-tabs>li>a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}
.table_1{
  width: 100%;
  font-size: 1.6rem;
}
.knopki_product{justify-content: space-around;margin-top: 3rem;display: flex;flex-direction: column;align-items: center;flex-direction: column;
  @media ($tablet) {flex-direction: row;
  }
}
.feature_od{background: $lightGrey;height: 4rem;}
.feature_even{background: $white;height: 4rem;}
.properties__container{width: 100%;display: flex;flex-direction: column;font-size: 1.6rem;   
  @media ($desktop) {max-height: 365px;overflow-y: auto;
  }
}
.quickPrice{font-size: 3.2rem;color: $prime;font-weight: 600;}
.properties__item{display: flex;line-height: 28px;color: $grey07;}
.properties__hr {
  margin: 0px 5px;
}
.char-side__title{font-size: 2.2rem;padding: 1rem 0}
.quickProperty{display:flex;width:100%;padding-top:2rem;border-bottom: 1px solid #999;padding-bottom: 2rem;flex-direction: column;
  @media ($tablet) {flex-direction: row;
    height: auto;
  }

}
.quickBtn{
  display: flex;justify-content: space-evenly;width: 100%;margin-top: 3.2rem;    flex-direction: column;align-items: center;
  @media ($desktop) {flex-direction: row;
  }
}
.inptKolvo{    background: #f5f5f5;
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  height: 40px;
  padding: 0 0.5rem;
  font-size: 1.5rem;
width:5rem;}