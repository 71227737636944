.ftr{    grid-template: auto / 1fr;
    gap: 1rem;
    margin-top: 4rem;
    display: grid;
    margin-bottom: 4rem;
    padding: 1rem;
     @media ($desktop) {
        padding-top: 5rem;
        grid-template: auto / 1fr  1fr 1fr 1fr;
      }
    }
.ftrZvonit{
font-size: 1.4rem;
font-weight: 400;
line-height: 0rem;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
width: 38.1rem;
height: 3.4rem;
color: $grey06;
}
.ftrGod{
font-size: 1.6rem;
font-weight: 400;
line-height: 1.909rem;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color:$grey05;
margin-left: 2rem;
@media ($desktop) {
 margin-top: 3rem;
 margin-left: 0;
}
}
.ftrMenu {
font-size: 1.6rem;
font-weight: 400;
line-height: 4rem;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: $black ;
cursor: pointer;
}
.ftrMenu:hover {color: $prime;}
.ftrZvon{
    
font-size: 2rem;
font-weight: 500;
line-height: 2.387rem;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: $prime;
cursor: pointer;

}
.address {
font-size: 1.6rem;
font-weight: 500;
line-height: 1.909rem;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: $black;
margin-top: 2.8rem;
}
.phoneNbr {
  font-size: 3.4rem;
  font-weight: 500;
  line-height: 1.773rem;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: $black;
  @media ($tablet) {font-size: 4rem;}
}
.phoneNbr:hover {color:$prime}
.menuBottom{
  display: flex;
  justify-content: space-around;
  width: 100%;
  box-shadow: 0px -4px 4px 0px #0000000D;
  padding: 1rem;
}
.menuBottomCatalog{    display: flex;
  flex-direction: column;
  align-items: center;
  @media ($desktop) {display: none;}
}