@font-face {
  font-family: SF Pro Display;
  src: local(SF Pro Display Regular), local(SFProDisplay-Regular), url("SFProDisplay-Regular.6ee7ed0c.woff2") format("woff2"), url("SFProDisplay-Regular.e131e5dc.woff") format("woff"), url("SFProDisplay-Regular.dd20a2e5.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
}

html, body {
  font-family: SF Pro Display;
  font-size: 10px;
}

h1, h2, h3 {
  font-family: SF Pro Display;
}

a {
  color: #0071e4;
  cursor: pointer;
  text-decoration: none;
}

input {
  outline: none;
  font-family: SF Pro Display;
}

@font-face {
  font-family: FontAwesome;
  src: url("fontawesome-webfont.58488e7e.woff2") format("woff2"), url("fontawesome-webfont.ed962b83.woff") format("woff"), url("fontawesome-webfont.0caf0c90.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.fa {
  font: 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
}

.fa-lg {
  vertical-align: -15%;
  font-size: 1.33333em;
  line-height: .75em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-fw {
  text-align: center;
  width: 1.28571em;
}

.fa-ul {
  margin-left: 2.14286em;
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  text-align: center;
  width: 2.14286em;
  position: absolute;
  top: .142857em;
  left: -2.14286em;
}

.fa-li.fa-lg {
  left: -1.85714em;
}

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right {
  margin-left: .3em;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.fa.pull-left {
  margin-right: .3em;
}

.fa.pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: 2s linear infinite fa-spin;
}

.fa-pulse {
  animation: 1s steps(8, end) infinite fa-spin;
}

@keyframes fa-spin {
  0% {
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -ms-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

:root .fa-rotate-90 {
  filter: none;
}

:root .fa-rotate-180 {
  filter: none;
}

:root .fa-rotate-270 {
  filter: none;
}

:root .fa-flip-horizontal {
  filter: none;
}

:root .fa-flip-vertical {
  filter: none;
}

.fa-stack {
  vertical-align: middle;
  width: 2em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-glass:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-envelope-o:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-o:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-remove:before {
  content: "";
}

.fa-close:before {
  content: "";
}

.fa-times:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-gear:before {
  content: "";
}

.fa-cog:before {
  content: "";
}

.fa-trash-o:before {
  content: "";
}

.fa-home:before {
  content: "";
}

.fa-file-o:before {
  content: "";
}

.fa-clock-o:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-arrow-circle-o-down:before {
  content: "";
}

.fa-arrow-circle-o-up:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-play-circle-o:before {
  content: "";
}

.fa-rotate-right:before {
  content: "";
}

.fa-repeat:before {
  content: "";
}

.fa-refresh:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-camera:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-dedent:before {
  content: "";
}

.fa-outdent:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-video-camera:before {
  content: "";
}

.fa-photo:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-picture-o:before {
  content: "";
}

.fa-pencil:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-edit:before {
  content: "";
}

.fa-pencil-square-o:before {
  content: "";
}

.fa-share-square-o:before {
  content: "";
}

.fa-check-square-o:before {
  content: "";
}

.fa-arrows:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-plus-circle:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-question-circle:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-times-circle-o:before {
  content: "";
}

.fa-check-circle-o:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-mail-forward:before {
  content: "";
}

.fa-share:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-warning:before {
  content: "";
}

.fa-exclamation-triangle:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-arrows-v:before {
  content: "";
}

.fa-arrows-h:before {
  content: "";
}

.fa-bar-chart-o:before {
  content: "";
}

.fa-bar-chart:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-gears:before {
  content: "";
}

.fa-cogs:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-thumbs-o-up:before {
  content: "";
}

.fa-thumbs-o-down:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-heart-o:before {
  content: "";
}

.fa-sign-out:before {
  content: "";
}

.fa-linkedin-square:before {
  content: "";
}

.fa-thumb-tack:before {
  content: "";
}

.fa-external-link:before {
  content: "";
}

.fa-sign-in:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-lemon-o:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-square-o:before {
  content: "";
}

.fa-bookmark-o:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-feed:before {
  content: "";
}

.fa-rss:before {
  content: "";
}

.fa-hdd-o:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-hand-o-right:before {
  content: "";
}

.fa-hand-o-left:before {
  content: "";
}

.fa-hand-o-up:before {
  content: "";
}

.fa-hand-o-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-group:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-chain:before {
  content: "";
}

.fa-link:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-cut:before {
  content: "";
}

.fa-scissors:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-files-o:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-save:before {
  content: "";
}

.fa-floppy-o:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-navicon:before {
  content: "";
}

.fa-reorder:before {
  content: "";
}

.fa-bars:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-money:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-unsorted:before {
  content: "";
}

.fa-sort:before {
  content: "";
}

.fa-sort-down:before {
  content: "";
}

.fa-sort-desc:before {
  content: "";
}

.fa-sort-up:before {
  content: "";
}

.fa-sort-asc:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-rotate-left:before {
  content: "";
}

.fa-undo:before {
  content: "";
}

.fa-legal:before {
  content: "";
}

.fa-gavel:before {
  content: "";
}

.fa-dashboard:before {
  content: "";
}

.fa-tachometer:before {
  content: "";
}

.fa-comment-o:before {
  content: "";
}

.fa-comments-o:before {
  content: "";
}

.fa-flash:before {
  content: "";
}

.fa-bolt:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-paste:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-lightbulb-o:before {
  content: "";
}

.fa-exchange:before {
  content: "";
}

.fa-cloud-download:before {
  content: "";
}

.fa-cloud-upload:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-bell-o:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-cutlery:before {
  content: "";
}

.fa-file-text-o:before {
  content: "";
}

.fa-building-o:before {
  content: "";
}

.fa-hospital-o:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-h-square:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-desktop:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-mobile-phone:before {
  content: "";
}

.fa-mobile:before {
  content: "";
}

.fa-circle-o:before {
  content: "";
}

.fa-quote-left:before {
  content: "";
}

.fa-quote-right:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-mail-reply:before {
  content: "";
}

.fa-reply:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-folder-o:before {
  content: "";
}

.fa-folder-open-o:before {
  content: "";
}

.fa-smile-o:before {
  content: "";
}

.fa-frown-o:before {
  content: "";
}

.fa-meh-o:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-keyboard-o:before {
  content: "";
}

.fa-flag-o:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-mail-reply-all:before {
  content: "";
}

.fa-reply-all:before {
  content: "";
}

.fa-star-half-empty:before {
  content: "";
}

.fa-star-half-full:before {
  content: "";
}

.fa-star-half-o:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-unlink:before {
  content: "";
}

.fa-chain-broken:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-shield:before {
  content: "";
}

.fa-calendar-o:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-unlock-alt:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-minus-square-o:before {
  content: "";
}

.fa-level-up:before {
  content: "";
}

.fa-level-down:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-pencil-square:before {
  content: "";
}

.fa-external-link-square:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-toggle-down:before {
  content: "";
}

.fa-caret-square-o-down:before {
  content: "";
}

.fa-toggle-up:before {
  content: "";
}

.fa-caret-square-o-up:before {
  content: "";
}

.fa-toggle-right:before {
  content: "";
}

.fa-caret-square-o-right:before {
  content: "";
}

.fa-euro:before {
  content: "";
}

.fa-eur:before {
  content: "";
}

.fa-gbp:before {
  content: "";
}

.fa-dollar:before {
  content: "";
}

.fa-usd:before {
  content: "";
}

.fa-rupee:before {
  content: "";
}

.fa-inr:before {
  content: "";
}

.fa-cny:before {
  content: "";
}

.fa-rmb:before {
  content: "";
}

.fa-yen:before {
  content: "";
}

.fa-jpy:before {
  content: "";
}

.fa-ruble:before {
  content: "";
}

.fa-rouble:before {
  content: "";
}

.fa-rub:before {
  content: "";
}

.fa-won:before {
  content: "";
}

.fa-krw:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-text:before {
  content: "";
}

.fa-sort-alpha-asc:before {
  content: "";
}

.fa-sort-alpha-desc:before {
  content: "";
}

.fa-sort-amount-asc:before {
  content: "";
}

.fa-sort-amount-desc:before {
  content: "";
}

.fa-sort-numeric-asc:before {
  content: "";
}

.fa-sort-numeric-desc:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-youtube-play:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bitbucket-square:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-long-arrow-down:before {
  content: "";
}

.fa-long-arrow-up:before {
  content: "";
}

.fa-long-arrow-left:before {
  content: "";
}

.fa-long-arrow-right:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-gittip:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-sun-o:before {
  content: "";
}

.fa-moon-o:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-arrow-circle-o-right:before {
  content: "";
}

.fa-arrow-circle-o-left:before {
  content: "";
}

.fa-toggle-left:before {
  content: "";
}

.fa-caret-square-o-left:before {
  content: "";
}

.fa-dot-circle-o:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-turkish-lira:before {
  content: "";
}

.fa-try:before {
  content: "";
}

.fa-plus-square-o:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-institution:before {
  content: "";
}

.fa-bank:before {
  content: "";
}

.fa-university:before {
  content: "";
}

.fa-mortar-board:before {
  content: "";
}

.fa-graduation-cap:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-spoon:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-automobile:before {
  content: "";
}

.fa-car:before {
  content: "";
}

.fa-cab:before {
  content: "";
}

.fa-taxi:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-file-pdf-o:before {
  content: "";
}

.fa-file-word-o:before {
  content: "";
}

.fa-file-excel-o:before {
  content: "";
}

.fa-file-powerpoint-o:before {
  content: "";
}

.fa-file-photo-o:before {
  content: "";
}

.fa-file-picture-o:before {
  content: "";
}

.fa-file-image-o:before {
  content: "";
}

.fa-file-zip-o:before {
  content: "";
}

.fa-file-archive-o:before {
  content: "";
}

.fa-file-sound-o:before {
  content: "";
}

.fa-file-audio-o:before {
  content: "";
}

.fa-file-movie-o:before {
  content: "";
}

.fa-file-video-o:before {
  content: "";
}

.fa-file-code-o:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-life-bouy:before {
  content: "";
}

.fa-life-buoy:before {
  content: "";
}

.fa-life-saver:before {
  content: "";
}

.fa-support:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-circle-o-notch:before {
  content: "";
}

.fa-ra:before {
  content: "";
}

.fa-resistance:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-ge:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-y-combinator-square:before {
  content: "";
}

.fa-yc-square:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-wechat:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-send:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-send-o:before {
  content: "";
}

.fa-paper-plane-o:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-circle-thin:before {
  content: "";
}

.fa-header:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-sliders:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-soccer-ball-o:before {
  content: "";
}

.fa-futbol-o:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-newspaper-o:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bell-slash-o:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-at:before {
  content: "";
}

.fa-eyedropper:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-area-chart:before {
  content: "";
}

.fa-pie-chart:before {
  content: "";
}

.fa-line-chart:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-cc:before {
  content: "";
}

.fa-shekel:before {
  content: "";
}

.fa-sheqel:before {
  content: "";
}

.fa-ils:before {
  content: "";
}

.fa-meanpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-intersex:before {
  content: "";
}

.fa-transgender:before {
  content: "";
}

.fa-transgender-alt:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-v:before {
  content: "";
}

.fa-mars-stroke-h:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-facebook-official:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-yc:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-battery-4:before {
  content: "";
}

.fa-battery:before {
  content: "";
}

.fa-battery-full:before {
  content: "";
}

.fa-battery-3:before {
  content: "";
}

.fa-battery-three-quarters:before {
  content: "";
}

.fa-battery-2:before {
  content: "";
}

.fa-battery-half:before {
  content: "";
}

.fa-battery-1:before {
  content: "";
}

.fa-battery-quarter:before {
  content: "";
}

.fa-battery-0:before {
  content: "";
}

.fa-battery-empty:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-sticky-note-o:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-hourglass-o:before {
  content: "";
}

.fa-hourglass-1:before {
  content: "";
}

.fa-hourglass-start:before {
  content: "";
}

.fa-hourglass-2:before {
  content: "";
}

.fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-3:before {
  content: "";
}

.fa-hourglass-end:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hand-grab-o:before {
  content: "";
}

.fa-hand-rock-o:before {
  content: "";
}

.fa-hand-stop-o:before {
  content: "";
}

.fa-hand-paper-o:before {
  content: "";
}

.fa-hand-scissors-o:before {
  content: "";
}

.fa-hand-lizard-o:before {
  content: "";
}

.fa-hand-spock-o:before {
  content: "";
}

.fa-hand-pointer-o:before {
  content: "";
}

.fa-hand-peace-o:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-tripadvisor:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-tv:before {
  content: "";
}

.fa-television:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-calendar-plus-o:before {
  content: "";
}

.fa-calendar-minus-o:before {
  content: "";
}

.fa-calendar-times-o:before {
  content: "";
}

.fa-calendar-check-o:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-map-o:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-commenting:before {
  content: "";
}

.fa-commenting-o:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-credit-card-alt:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-pause-circle-o:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stop-circle-o:before {
  content: "";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-hashtag:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-percent:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-wheelchair-alt:before {
  content: "";
}

.fa-question-circle-o:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-volume-control-phone:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-asl-interpreting:before {
  content: "";
}

.fa-american-sign-language-interpreting:before {
  content: "";
}

.fa-deafness:before {
  content: "";
}

.fa-hard-of-hearing:before {
  content: "";
}

.fa-deaf:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-signing:before {
  content: "";
}

.fa-sign-language:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-google-plus-circle:before {
  content: "";
}

.fa-google-plus-official:before {
  content: "";
}

.fa-fa:before {
  content: "";
}

.fa-font-awesome:before {
  content: "";
}

.fa-handshake-o:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-o:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-address-book:before {
  content: "";
}

.fa-address-book-o:before {
  content: "";
}

.fa-vcard:before {
  content: "";
}

.fa-address-card:before {
  content: "";
}

.fa-vcard-o:before {
  content: "";
}

.fa-address-card-o:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-circle-o:before {
  content: "";
}

.fa-user-o:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-drivers-license:before {
  content: "";
}

.fa-id-card:before {
  content: "";
}

.fa-drivers-license-o:before {
  content: "";
}

.fa-id-card-o:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-telegram:before {
  content: "";
}

.fa-thermometer-4:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-thermometer-full:before {
  content: "";
}

.fa-thermometer-3:before {
  content: "";
}

.fa-thermometer-three-quarters:before {
  content: "";
}

.fa-thermometer-2:before {
  content: "";
}

.fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-1:before {
  content: "";
}

.fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-0:before {
  content: "";
}

.fa-thermometer-empty:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-bathtub:before {
  content: "";
}

.fa-s15:before {
  content: "";
}

.fa-bath:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-times-rectangle:before {
  content: "";
}

.fa-window-close:before {
  content: "";
}

.fa-times-rectangle-o:before {
  content: "";
}

.fa-window-close-o:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-eercast:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-snowflake-o:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.bestH1 {
  text-align: center;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  margin-top: 3rem;
  font-family: SF Pro Display;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 3.82rem;
}

.itemBests {
  grid-template: auto / 1fr;
  justify-items: center;
  gap: 1rem;
  margin-top: 4rem;
  display: grid;
}

@media (min-width: 767px) {
  .itemBests {
    grid-template: auto / 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .itemBests {
    grid-template: auto / 1fr 1fr 1fr 1fr 1fr;
  }
}

.itemBest {
  border-radius: 16px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 228px;
  height: 500px;
  display: flex;
}

.itemBestName {
  text-align: center;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  font-family: SF Pro Display;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 2.87rem;
}

.itemBestOpis {
  text-align: center;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #585656;
  text-align: justify;
  padding: 0 1rem;
  font-family: SF Pro Display;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.91rem;
}

.itemBestBtn {
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #0071e4;
  cursor: pointer;
  border: 2px solid #0071e4;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 21.2rem;
  height: 5.6rem;
  font-family: SF Pro Display;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.15rem;
  display: flex;
}

.itemBestBtn:hover {
  color: #fff;
  background: #0071e4;
}

#remont {
  background-color: #f9f9f9;
  width: 100%;
  margin-top: 5rem;
}

.rmnt {
  grid-template: auto / 1fr;
  justify-content: center;
  justify-items: center;
  gap: 1rem;
  display: grid;
}

@media (min-width: 767px) {
  .rmnt {
    grid-template: auto / 1fr;
  }
}

@media (min-width: 1024px) {
  .rmnt {
    grid-template: auto / 1fr 1fr;
  }
}

.rmntImg {
  height: 31rem;
}

@media (min-width: 767px) {
  .rmntImg {
    height: 43rem;
  }
}

@media (min-width: 1024px) {
  .rmntImg {
    height: 48.1rem;
  }
}

.rmntOpis {
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  display: flex;
}

.rmtName {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  font-family: SF Pro Display;
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 3.8rem;
}

@media (min-width: 767px) {
  .rmtName {
    font-size: 4.8rem;
    line-height: 3.8rem;
  }
}

@media (min-width: 1024px) {
  .rmtName {
    font-size: 4.8rem;
    line-height: 5.73rem;
  }
}

.rmntPodpis {
  color: #585656;
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  height: 58px;
  margin: 10px 0;
  font-family: SF Pro Display;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.86rem;
}

.rmntZajavka {
  background: linear-gradient(90.34deg, #f7355d .3%, #9747ff 101.33%);
  border-radius: 16px;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 2rem;
}

@media (min-width: 1024px) {
  .rmntZajavka {
    width: 486px;
    height: 196px;
  }
}

.rmntZajavkaName {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #fff;
  font-family: SF Pro Display;
  font-size: 4rem;
  font-weight: 700;
  line-height: 4.7rem;
}

.rmntZajavkaNameOpis {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #fff;
  font-family: SF Pro Display;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.3rem;
}

.rmntZajavkaOut {
  flex-flow: column;
  margin-top: 2rem;
  display: flex;
}

@media (min-width: 767px) {
  .rmntZajavkaOut {
    flex-direction: column;
  }
}

@media (min-width: 1024px) {
  .rmntZajavkaOut {
    flex-direction: row;
    justify-content: space-around;
  }
}

.rmmntZajavkaOutInpt {
  border: 1px 0px 0px 0px;
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  border-radius: 8px;
  gap: 0;
  width: 100%;
  height: 5.6rem;
  margin-bottom: .5rem;
  padding: 13px 17px;
  font-family: SF Pro Display;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.86rem;
}

@media (min-width: 767px) {
  .rmmntZajavkaOutInpt {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .rmmntZajavkaOutInpt {
    width: 24.6rem;
  }
}

.rmntZajavkaOutBtn {
  text-align: center;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #585656;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
  height: 5.6rem;
  font-family: SF Pro Display;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.91rem;
}

@media (min-width: 767px) {
  .rmntZajavkaOutBtn {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .rmntZajavkaOutBtn {
    width: 16rem;
  }
}

.rmntZajavkaOutBtn:hover {
  color: #100e0e;
  background-color: #b7b7b7;
}

.cartItem {
  border-radius: 16px;
  flex-direction: column;
  gap: 0;
  width: 100%;
  padding-top: 2rem;
  display: flex;
}

.cartItem_name {
  text-align: left;
  color: #100e0e;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.909rem;
}

.cartItem_Stroks {
  flex-direction: column;
  width: 100%;
  display: flex;
}

@media (min-width: 1024px) {
  .cartItem_Stroks {
    width: 70%;
    padding: 1rem;
  }
}

.cartItem_Stroka {
  cursor: pointer;
  border-radius: 8px;
  grid-template: auto / 1fr;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem 0;
  display: grid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .05);
}

@media (min-width: 1024px) {
  .cartItem_Stroka {
    grid-template: auto / 1fr 15% 10%;
    width: auto;
  }
}

.cartItem_Stroka:hover {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .31);
}

.cartItem_Summa {
  width: 100%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .05);
}

@media (min-width: 1024px) {
  .cartItem_Summa {
    width: 30%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .05);
  }

  .cartItem {
    flex-direction: row;
  }
}

.cartItem_del {
  color: #0071e4;
  cursor: pointer;
  text-align: right;
  margin-right: 1rem;
  font-size: 2rem;
}

.cartItem_Price {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.cartItem_PriceOld {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #585656;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.909rem;
  text-decoration-line: line-through;
}

.cartItem_PriceNow {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  counter-reset: #100e0e;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.909rem;
}

.cartItem_Kolvo {
  text-align: center;
  color: #0071e4;
  background: #fafafa;
  border-radius: 8px;
  width: 7.1rem;
  height: 3.2rem;
  font-size: 1.6rem;
  font-weight: 600;
}

.cartItem_back {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #878787;
  margin-top: 3rem;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.909rem;
}

.cartItem_back:hover {
  color: #0071e4;
  cursor: pointer;
}

.cartSumma {
  padding: 2rem;
}

.cartSumma_Dostavka {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #282626;
  justify-content: space-between;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.387rem;
  display: flex;
}

.cartSumma_phone {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #100e0e;
  margin-top: 1rem;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.864rem;
}

.cartSumma_Dostavka_Summ {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #585656;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.387rem;
}

.cartSumma_dstvk {
  color: #282626;
  gap: 0;
  height: 24px;
  font-size: 2rem;
  font-weight: 700;
  top: 235px;
  left: 396px;
}

.cartSumma_radio {
  justify-content: space-around;
  display: flex;
}

.cartSumma_radio input[type="radio"] + label {
  height: 40px;
  font-size: 1.6rem;
}

.cartSumma_radioInpt {
  opacity: 0px;
  border-radius: 500px 0 0;
  gap: 0;
  width: 2.4rem;
  height: 2.4rem;
  top: 4px;
  left: 4px;
}

.cartSumma_select {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #100e0e;
  background: #f9f9f9;
  border: none;
  border-radius: 8px;
  gap: 4px;
  width: 100%;
  height: 40px;
  padding: 8px 0 0;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.909rem;
  top: 267px;
  left: 396px;
}

.cartSumma_btn {
  color: #fff;
  cursor: pointer;
  background: #0071e4;
  border-radius: 16px;
  align-items: center;
  width: 100%;
  height: 56px;
  font-size: 2rem;
}

.cartSumma_btn:hover {
  background: #004d9b;
}

.cartItem_del {
  grid-area: 1 / 1;
}

@media (min-width: 1024px) {
  .cartItem_del {
    grid-area: 3 / 3;
    margin-top: -6rem;
  }
}

.popup-cart {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.popup-zvonok {
  background: #fff;
  border-radius: 5px;
  height: 33rem;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
}

.cartSumma_radio {
  border: none;
}

.cartSumma_radio input {
  cursor: pointer;
}

.product {
  margin-top: 3rem;
}

.product_item {
  flex-direction: column;
  margin-top: 3rem;
  display: flex;
}

@media (min-width: 1024px) {
  .product_item {
    flex-direction: row;
  }
}

.product_h1 {
  margin-top: 2rem;
  font-size: 2.4rem !important;
}

@media (min-width: 1024px) {
  .product_h1 {
    font-size: 3.2rem !important;
  }
}

.product_img {
  box-sizing: border-box;
  border: 1px solid #777;
  width: 100%;
}

@media (min-width: 1024px) {
  .product_img {
    width: 442px;
  }
}

.product_har {
  color: #0071e4;
  font-size: 2.4rem;
  font-weight: 700;
}

.product_manuf {
  color: #585656;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 3.2rem;
  display: flex;
}

@media (min-width: 1024px) {
  .product_manuf {
    margin-top: 3rem;
  }
}

.product_property {
  width: 100%;
  margin-top: 3rem;
}

@media (min-width: 1024px) {
  .product_property {
    margin-top: 0;
    padding-left: 3rem;
  }
}

.product_skolko {
  flex-direction: column;
  justify-content: space-between;
  margin-top: 3rem;
  display: flex;
}

@media (min-width: 1024px) {
  .product_skolko {
    flex-direction: row;
  }
}

.product_kolvo {
  align-items: center;
  display: flex;
}

.product_infa {
  border-bottom: 1px solid #f3f3f3;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 3rem;
  padding-bottom: 2rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 30px;
  display: flex;
}

@media (min-width: 1024px) {
  .product_infa {
    flex-direction: row;
  }
}

.product_summa {
  flex-direction: row;
  justify-content: space-between;
  padding-top: .5rem;
  font-size: 1.6rem;
  line-height: 3rem;
  display: flex;
}

@media (min-width: 1024px) {
  .product_summa {
    flex-direction: column;
  }
}

.product_description {
  margin-top: 3rem;
  font-size: 2rem;
  font-weight: 400;
}

#minus {
  text-align: center;
  cursor: pointer;
  background: #d9d9d9;
  border-radius: 5px 0 0 5px;
  width: 41px;
  height: 36px;
  font-size: 24px;
}

#plus {
  text-align: center;
  cursor: pointer;
  background: #d9d9d9;
  border-radius: 0 5px 5px 0;
  width: 41px;
  height: 36px;
  font-size: 24px;
}

#inpt_q {
  text-align: center;
  border: 1px solid #d9d9d9;
  width: 49px;
  height: 36px;
  font-size: 17px;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
  width: 127px;
  font-size: 1.6rem;
}

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: rgba(0, 0, 0, 0);
}

.nav-tabs > li > a:hover {
  border-color: #eee #eee #ddd;
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: #eee;
  text-decoration: none;
}

.nav > li > a {
  padding: 10px 15px;
  display: block;
  position: relative;
}

.nav-tabs > li > a {
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px 4px 0 0;
  margin-right: 2px;
  line-height: 1.42857;
}

.table_1 {
  width: 100%;
  font-size: 1.6rem;
}

.knopki_product {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 3rem;
  display: flex;
}

@media (min-width: 767px) {
  .knopki_product {
    flex-direction: row;
  }
}

.feature_od {
  background: #f9f9f9;
  height: 4rem;
}

.feature_even {
  background: #fff;
  height: 4rem;
}

.properties__container {
  flex-direction: column;
  width: 100%;
  font-size: 1.6rem;
  display: flex;
}

@media (min-width: 1024px) {
  .properties__container {
    max-height: 365px;
    overflow-y: auto;
  }
}

.quickPrice {
  color: #0071e4;
  font-size: 3.2rem;
  font-weight: 600;
}

.properties__item {
  color: #585656;
  line-height: 28px;
  display: flex;
}

.properties__hr {
  margin: 0 5px;
}

.char-side__title {
  padding: 1rem 0;
  font-size: 2.2rem;
}

.quickProperty {
  border-bottom: 1px solid #999;
  flex-direction: column;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
}

@media (min-width: 767px) {
  .quickProperty {
    flex-direction: row;
    height: auto;
  }
}

.quickBtn {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 3.2rem;
  display: flex;
}

@media (min-width: 1024px) {
  .quickBtn {
    flex-direction: row;
  }
}

.inptKolvo {
  background: #f5f5f5;
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  width: 5rem;
  height: 40px;
  padding: 0 .5rem;
  font-size: 1.5rem;
}

.ktlg {
  color: #0071e4;
  font-size: 1.6rem;
  font-weight: 600;
}

.ktlg_description {
  background: #f9f9f9;
  display: none;
}

@media (min-width: 767px) {
  .ktlg_description {
    display: none;
  }
}

@media (min-width: 1024px) {
  .ktlg_description {
    display: block;
  }
}

.ktlg_subMenu {
  color: #585656;
  column-count: 1;
  grid-template: auto / 1fr;
  padding: 1rem 1rem 1rem 47px;
  line-height: 3rem;
}

@media (min-width: 767px) {
  .ktlg_subMenu {
    column-count: 2;
  }
}

@media (min-width: 1024px) {
  .ktlg_subMenu {
    column-count: 3;
  }

  .ktlg_separator {
    box-shadow: -4px 0 rgba(0, 0, 0, .05);
  }
}

.ktlg_subMenu li:hover {
  color: #0071e4 !important;
}

.ktlg_menu {
  display: none;
}

@media (min-width: 1024px) {
  .ktlg_menu {
    display: block;
  }
}

.ktlg_H3 {
  padding-left: 1rem;
  display: none;
}

@media (min-width: 1024px) {
  .ktlg_H3 {
    display: block;
  }
}

.ktlg_select {
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
}

@media (min-width: 1024px) {
  .ktlg_select {
    display: none;
  }

  .ktlg {
    grid-template: auto / 1fr 20%;
  }
}

.ktlg ul li:hover {
  color: #585656;
  background: #f5f5f5 !important;
}

.ktlg ul {
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-right: 1rem;
}

.ktlg ul li {
  cursor: pointer;
  margin-left: -3.7rem;
  padding: 0 1rem;
  line-height: 3rem;
  list-style: none;
}

.description_cat {
  text-align: left;
  color: #706e6e;
  height: 7.5rem;
  margin-top: 1rem;
  padding: 1rem;
  font-size: 1.3rem;
  line-height: 1.7rem;
  overflow-y: auto;
}

.group_description_block {
  background: #f5f5f5;
  border-radius: 8px;
  margin-top: 1.3rem;
  padding: 1rem;
  font-size: 1.6rem;
}

#ktlg {
  overflow-y: auto;
}

.lstProduct {
  grid-template: auto / 1fr;
  justify-items: center;
  gap: 1rem;
  display: grid;
}

@media (min-width: 767px) {
  .lstProduct {
    grid-template: auto / 1fr 1fr;
    justify-items: center;
    gap: 1rem;
  }
}

@media (min-width: 1024px) {
  .lstProduct {
    grid-template: auto / 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}

.itemProduct {
  cursor: pointer;
  border-radius: 8px 0 0;
  flex-direction: column;
  justify-content: space-around;
  gap: 0;
  width: 259px;
  height: 38rem;
  display: flex;
  top: 59px;
  left: 31px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .05);
}

.itemProduct:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, .25);
}

.stars {
  align-items: center;
  margin-left: 16px;
  display: flex;
}

.star {
  gap: 0;
  width: 24px;
  height: 24px;
  padding: 2px;
}

.otzyvs {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #0071e4;
  font-family: SF Pro Display;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.91rem;
}

.itemProductImg {
  gap: 0;
  width: 140rem;
  height: 15.2rem;
  position: relative;
  left: 6rem;
}

.itemProductName {
  text-align: center;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #100e0e;
  height: 9rem;
  margin-top: 1rem;
  padding: 0 .5rem;
  font-family: SF Pro Display;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.15rem;
}

.itemProductInfo {
  justify-content: space-around;
  display: flex;
}

.itemProductInfoNal {
  color: #706e6e;
}

.itemProductPrice {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.itemProductPriceBtn {
  background: #0071e4;
  border-radius: 8px;
  justify-content: space-around;
  align-items: center;
  gap: 0;
  width: 125px;
  height: 40px;
  display: flex;
}

.itemProductPriceBtn:hover {
  background: #004d9b;
}

.itemProductPriceCena {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #fff;
  font-family: SF Pro Display;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.39rem;
}

.itemProductPriceOld {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #878787;
  font-family: SF Pro Display;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.91rem;
}

.itemProductKupitP {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #0071e4;
  font-family: SF Pro Display;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.67rem;
}

.itemProductKupit {
  justify-content: space-around;
  margin-bottom: 1.5rem;
  display: flex;
}

#helpNetF1 {
  background-color: #000;
  background-image: url("zapravka.98d7a024.jpg");
  background-size: cover;
  flex-direction: column;
  width: 100%;
  height: 280px;
  display: flex;
}

.helpNetH1 {
  text-align: center;
  padding-top: 5rem;
  font-size: 6rem;
  color: #fff !important;
}

.helpNetH2 {
  text-align: center;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.86rem;
}

.helpNetBtn {
  color: #fff;
  background: #0071e4;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 5.6rem;
  display: flex;
  width: 16rem !important;
}

.parallax-window {
  background: none;
}

.ftr {
  grid-template: auto / 1fr;
  gap: 1rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding: 1rem;
  display: grid;
}

@media (min-width: 1024px) {
  .ftr {
    grid-template: auto / 1fr 1fr 1fr 1fr;
    padding-top: 5rem;
  }
}

.ftrZvonit {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #706e6e;
  width: 38.1rem;
  height: 3.4rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 0;
}

.ftrGod {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #878787;
  margin-left: 2rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.909rem;
}

@media (min-width: 1024px) {
  .ftrGod {
    margin-top: 3rem;
    margin-left: 0;
  }
}

.ftrMenu {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #100e0e;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 4rem;
}

.ftrMenu:hover {
  color: #0071e4;
}

.ftrZvon {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #0071e4;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.387rem;
}

.address {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #100e0e;
  margin-top: 2.8rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.909rem;
}

.phoneNbr {
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #100e0e;
  font-size: 3.4rem;
  font-weight: 500;
  line-height: 1.773rem;
}

@media (min-width: 767px) {
  .phoneNbr {
    font-size: 4rem;
  }
}

.phoneNbr:hover {
  color: #0071e4;
}

.menuBottom {
  justify-content: space-around;
  width: 100%;
  padding: 1rem;
  display: flex;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, .05);
}

.menuBottomCatalog {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (min-width: 1024px) {
  .menuBottomCatalog {
    display: none;
  }
}

#content {
  min-height: calc(100vh - 50rem);
}

.container {
  margin: auto;
  overflow: hidden;
}

@media (min-width: 767px) {
  .container {
    width: 90%;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 1440px;
  }
}

.h1 {
  color: #100e0e;
  font-famaly: "SF Pro Display";
  font-size: 2.4rem;
  font-weight: 500;
}

@media (min-width: 767px) {
  .h1 {
    font-size: 3rem;
  }
}

@media (min-width: 1024px) {
  .h1 {
    font-size: 4.8rem;
  }
}

.h2 {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #0071e4;
  margin-bottom: 2rem;
  font-family: SF Pro Display;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.91rem;
}

@media (min-width: 767px) {
  .h2 {
    font-size: 2.4rem;
    line-height: 2.86rem;
  }
}

@media (min-width: 1024px) {
  .h2 {
    font-size: 2.4rem;
    line-height: 2.86rem;
  }
}

.cntr {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.top-info {
  background: #f9f9f9;
  width: 100%;
  display: none;
}

@media (min-width: 1024px) {
  .top-info {
    height: 5.1rem;
    display: flex;
  }
}

.f1-animation {
  -ms-transform-origin: center;
  transform-origin: center;
  animation: 1s linear infinite moving;
}

@keyframes moving {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.prime {
  color: #0071e4;
}

.topPanel {
  color: #fff;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  display: none;
}

@media (min-width: 767px) {
  .topPanel {
    display: flex;
  }
}

@media (min-width: 1024px) {
  .topPanel {
    height: 5.1rem;
    display: flex;
  }
}

#topPanel {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#topMenu {
  align-items: center;
  height: 4rem;
  margin-left: 2rem;
  line-height: 2.15rem;
  display: none;
}

@media (min-width: 1024px) {
  #topMenu {
    height: 5.1rem;
    display: flex;
  }
}

#topMenu a {
  color: #100e0e;
  margin-left: 3.5rem;
  font-size: 1.8rem;
}

#topMenu a:hover {
  color: #0071e4;
}

.phone {
  color: #100e0e;
  font-size: 2rem;
}

#phone {
  display: none;
}

@media (min-width: 1024px) {
  #phone {
    align-items: flex-start;
    padding-top: 1.8rem;
    padding-bottom: .5rem;
    display: flex;
  }
}

.zvonit {
  color: #0071e4;
  float: right;
  cursor: pointer;
  font-size: 1.6rem;
}

.garant {
  text-decoration: underline;
  color: #0071e4 !important;
}

#topPanelFix {
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 6rem;
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .05);
}

@media (min-width: 767px) {
  #topPanelFix {
    width: 700px;
  }
}

@media (min-width: 1024px) {
  #topPanelFix {
    height: 5.1rem;
    display: none;
  }
}

.topPanelFixNav {
  justify-content: space-between;
  width: 50%;
  display: flex;
}

@media (min-width: 767px) {
  .topPanelFixNav {
    width: 30%;
  }
}

.inptTablet {
  display: none;
}

@media (min-width: 767px) {
  .inptTablet {
    display: flex;
  }

  .noTablet {
    display: none;
  }
}

.noMbl {
  display: none !important;
}

@media (min-width: 767px) {
  .noMbl {
    display: flex !important;
  }
}

#slider {
  width: 100%;
}

.slider {
  background-color: #b3e0ff;
  width: 100%;
}

@media (min-width: 767px) {
  .slider {
    height: 310px;
  }
}

@media (min-width: 1024px) {
  .slider {
    height: 485px;
  }
}

.akcii {
  background: #fdefbf;
  border-radius: 10px;
  padding: .5rem;
}

.btnCat {
  color: #fff;
  cursor: pointer;
  background: #0071e4;
  border-radius: 16px;
  align-items: center;
  width: 215px;
  height: 56px;
  margin-bottom: 1rem;
  font-size: 2rem;
  display: flex;
}

.btnCat:hover {
  background: #004d9b;
}

.btnCatGreen {
  color: #fff;
  cursor: pointer;
  background: #52d116;
  border-radius: 16px;
  justify-content: space-around;
  align-items: center;
  width: 215px;
  height: 56px;
  font-size: 2rem;
  display: flex;
}

.btnCatGreen:hover {
  background: #2f9300;
}

#panelFixDesk {
  justify-content: space-between;
  align-items: center;
  height: 100px;
  display: none;
}

@media (min-width: 1024px) {
  #panelFixDesk {
    display: flex;
  }
}

#panelFixDesk input {
  border: none;
  width: 100%;
  font-size: 2rem;
}

.search {
  border: 1px solid #b7b7b7;
  border-radius: 16px;
  align-items: center;
  width: 80rem;
  height: 56px;
  padding: 8px;
  display: inline-flex;
}

.search-icon {
  order: -1;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.search-field {
  width: 300px;
  border: none !important;
}

.search-field:focus {
  outline: none;
  border: none !important;
}

.search-field:focus + .search-icon {
  border: none !important;
}

.btnKol-vo {
  background-image: url("kol-vo.504ea011.png");
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 32px;
  display: flex;
}

#panelFixDeskCat {
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  display: none;
}

@media (min-width: 1024px) {
  #panelFixDeskCat {
    display: flex;
  }
}

#panelFixDeskCat a {
  color: #100e0e;
  margin-left: 5rem;
  font-size: 2rem;
}

#panelFixDeskCat div {
  cursor: pointer;
  justify-content: space-around;
  align-items: center;
  padding-right: 1rem;
  display: flex;
}

#panelFixDeskCat div a:hover {
  color: #0071e4;
}

.constructor {
  color: #fff;
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  background: linear-gradient(90deg, #000 22.19%, #5e556b 100%);
  border-radius: 8px;
  justify-content: center;
  gap: 0;
  width: 280px;
  height: 64px;
  font-family: SF Pro Display;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.9rem;
  top: 8px;
  left: 1040px;
}

.constructor:hover {
  background: linear-gradient(90deg, #000 22.19%, #7d738b 100%);
}

.imgSlide {
  height: 200px;
}

@media (min-width: 767px) {
  .imgSlide {
    height: 280px;
  }
}

@media (min-width: 1024px) {
  .imgSlide {
    height: 485px;
  }
}

.circle {
  z-index: -1;
  background: linear-gradient(to right, red, #0071e4, #28186d);
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  display: flex;
  border-radius: 50px !important;
}

.imgLogo {
  box-sizing: border-box;
  border: 3px solid #fff;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: -82px;
}

.sld {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 767px) {
  .sld {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .sld {
    flex-direction: row;
  }
}

.delo {
  color: #173d7b;
  flex-direction: column;
  height: 100%;
  margin-bottom: 2rem;
  margin-left: 1rem;
  font-size: 2.1rem;
  line-height: 2.5rem;
  display: flex;
}

@media (min-width: 767px) {
  .delo {
    margin-bottom: 0;
    font-size: 2.1rem;
    line-height: 2.8rem;
  }
}

@media (min-width: 1024px) {
  .delo {
    margin-bottom: 0;
    font-size: 3rem;
    line-height: 4rem;
  }
}

.cat1 {
  text-align: right;
  background-image: url("iphonex.51757d15.svg");
  background-repeat: no-repeat;
  height: 50px;
}

.cat1:hover {
  background-image: url("iphonex-blue.e5eaac17.svg");
}

.cat2 {
  text-align: right;
  background-image: url("laptop.08c5cf18.svg");
  background-repeat: no-repeat;
  height: 48px;
}

.cat2:hover {
  background-image: url("laptop-blue.bdaf95b2.svg");
}

.cat3 {
  text-align: right;
  background-image: url("noutbook.970d234c.svg");
  background-repeat: no-repeat;
  background-size: 48px;
  height: 48px;
}

.cat3:hover {
  background-image: url("noutbook-blue.7fcb10be.svg");
}

.cat4 {
  text-align: right;
  background-image: url("monitor.6e1afcd0.svg");
  background-repeat: no-repeat;
  height: 48px;
}

.cat4:hover {
  background-image: url("monitor-blue.facdbdfc.svg");
}

.cat5 {
  text-align: right;
  background-image: url("printer.f29d5503.png");
  background-repeat: no-repeat;
  height: 48px;
}

.cat5:hover {
  background-image: url("printer-blue.ddefbb2d.png");
}

.cat6 {
  text-align: right;
  background-image: url("smart-home.8d79b636.png");
  background-repeat: no-repeat;
  height: 48px;
}

.cat6:hover {
  background-image: url("smart-home-blue.3f1e8b41.png");
}

.sldLogo {
  height: 48px;
  margin-top: 10px;
  margin-left: 10px;
}

@media (min-width: 767px) {
  .sldLogo {
    height: 37px;
    margin-top: 0;
    margin-left: 4rem;
  }
}

@media (min-width: 1024px) {
  .sldLogo {
    height: 98px;
    margin-top: 0;
    margin-left: 4rem;
  }
}

.ftrLogoMbl {
  display: flex;
}

@media (min-width: 1024px) {
  .ftrLogoMbl {
    flex-direction: column;
  }
}

.popup-fade {
  background: #fff;
  border-radius: 5px;
  width: 553px;
  height: 297px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
}

.popup-fadeProduct {
  background: #fff;
  border-radius: 5px;
  width: 95%;
  height: auto;
  padding: 20px;
  position: absolute;
  top: 70rem;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
}

@media (min-width: 767px) {
  .popup-fadeProduct {
    top: 45rem;
  }
}

@media (min-width: 1024px) {
  .popup-fadeProduct {
    width: 50%;
    height: 70rem;
    top: 50%;
  }
}

.popup-fadeCatalog {
  background: #fff;
  border-radius: 5px;
  width: 95%;
  height: 95%;
  padding: 20px;
  position: absolute;
  top: 55%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
}

@media (min-width: 767px) {
  .popup-fadeCatalog {
    width: 95%;
    height: 95%;
  }
}

@media (min-width: 1024px) {
  .popup-fadeCatalog {
    width: 80%;
    height: 80%;
  }
}

.popup-close {
  color: #0071e4;
  float: right;
  cursor: pointer;
  padding: 1rem;
  font-size: 2.4rem;
}

.popupH1 {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #100e0e;
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 3.819rem;
}

.popupPerezvonit {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #585656;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.909rem;
}

.poupTelo {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: auto;
  display: flex;
}

@media (min-width: 1024px) {
  .poupTelo {
    height: 20rem;
  }
}

.popupBtn {
  text-align: left;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: #fff;
  text-align: center;
  cursor: pointer;
  width: 24.6rem;
  height: 5.6rem;
  background: #0071e4;
  border-radius: 8px;
  gap: 8px;
  width: 24.6rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.909rem;
}

.popupBtn:hover {
  background: #004d9b;
}

.popup-overlay {
  z-index: 11;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(58, 63, 68, .47);
  width: 100%;
  height: 100%;
  margin-top: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.faCat {
  color: #585656;
  cursor: pointer;
  margin-left: 2rem;
  font-size: 3rem;
}

.faCat:hover {
  color: #0071e4;
}

.top {
  display: none;
}

@media (min-width: 1024px) {
  .top {
    cursor: pointer;
    display: block;
    position: fixed;
    bottom: 10rem;
    right: 5%;
  }
}

.popupCartItem {
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 78px;
  padding: .5rem;
  display: flex;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .05);
}

.popupCartItem:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, .294);
}

.page404 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 3rem;
  display: flex;
}

@media (min-width: 1024px) {
  .page404 {
    margin-top: 0;
  }
}

.page404Podpis {
  color: #585656;
  font-size: 1.6rem;
}

.popupImg {
  width: 100%;
}

@media (min-width: 767px) {
  .popupImg {
    width: auto;
  }
}

.navRaz {
  color: #52d116;
  font-size: 1.6rem;
  font-weight: 600;
}

nav a {
  font-size: 1.6rem;
}

#content {
  margin-top: 6rem;
  padding: 1.5rem;
}

@media (min-width: 1024px) {
  #content {
    margin-top: 0;
  }
}

.popup-fadeCatalog {
  background: #fff;
}

.cookies {
  color: #fff;
  z-index: 1000;
  background: rgba(0, 0, 0, .74);
  border-radius: 5px;
  padding: 10px;
  font-size: 1.6rem;
  display: flex;
  position: fixed;
  bottom: 4px;
  right: 3%;
  box-shadow: 0 0 5px rgba(0, 0, 0, .1);
}

.btn_cooki {
  cursor: pointer;
  background: #d52623;
  border: none;
  border-radius: 5px;
  margin-left: 1rem;
}

.popup-fadeMsg {
  z-index: 22;
  background: rgba(102, 156, 220, .42);
  border-radius: 8px;
  padding: 20px;
  font-size: 1.6rem;
  position: fixed;
  top: 25%;
}

.poupTeloZvonok {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 23.4rem;
  display: flex;
}

.lupa {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  margin-left: 2rem;
}

.inptTop {
  border: none;
  width: 226px;
  font-size: 1.6rem;
}

@media (min-width: 767px) {
  .inptTop {
    width: 320px;
  }
}

.popup-gif {
  width: 100%;
  height: 100%;
  margin-top: 0;
  position: fixed;
  top: 0;
  left: 0;
}

.demoTabs {
  color: #4a4440;
  background-color: #fff;
  display: block;
}

.demoTabs__content {
  border-top: 1px solid #d9d9d9;
  margin-top: .8rem;
  padding: 16px;
}

.demoTabs__tab, .demoTabs__area {
  display: none;
}

.demoTabs__area p {
  margin-bottom: 16px;
}

.demoTabs__label {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 8px 8px 0 0;
  flex-direction: column;
  margin-right: 2px;
  padding: 1rem;
  line-height: 1.42857;
  display: flex;
}

@media (min-width: 767px) {
  .demoTabs__label {
    display: initial;
  }
}

.demoTabs__label:hover {
  background-color: #d9d9d9;
}

.demoTabs__tab:checked + .demoTabs__label {
  background-color: #d9d9d9;
}

.demoTabs #demoTab-1:checked ~ .demoTabs__content .demoTabs__area[data-tab="demoTab-1"] {
  display: block;
}

.demoTabs #demoTab-2:checked ~ .demoTabs__content .demoTabs__area[data-tab="demoTab-2"] {
  display: block;
}

.demoTabs #demoTab-3:checked ~ .demoTabs__content .demoTabs__area[data-tab="demoTab-3"] {
  display: block;
}

.font_xs {
  display: flex;
}

.properties__title {
  width: 100%;
}

@media (min-width: 767px) {
  .properties__title {
    width: 33%;
  }
}

.properties__title:nth-child(2n) {
  background: #f5f5f5;
}

.properties__value {
  margin-left: 1rem;
}

.popup-overlayChat {
  display: none;
}

/*# sourceMappingURL=index.e79ab756.css.map */
