//colors
$white:#FFF;
$black:#100E0E;
$prime:#0071E4;
$grey03:#B7B7B7;
$lightGrey:#F9F9F9;
$grey05:#878787;
$grey01:#E7E7E7;
$grey06:#706E6E;
$grey07:#585656;
$grey09:#282626;
$grey015:#DBDBDB;
$litePrime:#2A93FD;
$iconLight:#CCCCCC;
$green:#52D116;
//font
$mainFont: 'SF Pro Display';
$iconFont: 'Font Awesome 5 Free';
//screens
$tablet: '(min-width:767px)';
$desktop: '(min-width:1024px)';
