* {
    margin: 0;
    box-sizing: border-box;
}
html,body {
    font-size: 10px;
    font-family: $mainFont;
}
h1,h2,h3 {
    font-family: $mainFont;
}
a {color: $prime;cursor: pointer;    text-decoration: none;}
input {outline:none;font-family: $mainFont;}