.lstProduct {grid-template: auto / 1fr;
gap: 1rem;
display: grid;
justify-items: center;
@media ($tablet) {
    grid-template: auto / 1fr 1fr ;
    justify-items: center;
    gap: 1rem;
}

@media ($desktop) {
    grid-template: auto / 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}
}
.itemProduct {
    box-shadow: 0px 4px 8px 0px #0000000D;
    width: 259px;
    height: 38rem;
    top: 59px;
    left: 31px;
    gap: 0px;
    border-radius: 8px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
}
.itemProduct:hover {
    box-shadow: 0px 4px 8px 0px #00000040;
}
.stars{     display: flex;
    align-items: center; margin-left: 16px;}
.star {
     width: 24px;
    height: 24px;
    padding: 2px;
    gap: 0px;
    }
.otzyvs {
    font-family: $mainFont;
font-size: 1.6rem;
font-weight: 500;
line-height: 1.91rem;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color:$prime;
} 
.itemProductImg {
    position: relative;
    width: 140rem;
    height: 15.2rem;
    left: 6rem;
    gap: 0px;
}
.itemProductName {font-family: $mainFont;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.15rem;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $black;
    padding: 0 0.5rem;
    height: 9rem;
    margin-top: 1rem;
    }
.itemProductInfo {    display: flex;
    justify-content: space-around;
}
.itemProductInfoNal {color:$grey06}
.itemProductPrice{display: flex;justify-content: space-around;
    align-items: center;
}
.itemProductPriceBtn{
    width: 125px;
    height: 40px;
    gap: 0px;
    border-radius: 8px;
    background:$prime;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.itemProductPriceBtn:hover{background: #004d9b;}
.itemProductPriceCena {font-family:$mainFont;
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.39rem;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $white;
    }
.itemProductPriceOld {font-family: $mainFont;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.91rem;
    text-align: left;
   /* text-decoration-line: line-through;
   */ text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $grey05;
    }
.itemProductKupitP {@include itemProductKupitP()}
.itemProductKupit {display:flex;justify-content: space-around;margin-bottom: 1.5rem;}